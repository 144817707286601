import { useState, useEffect } from "react";
import axios from "../axios";

// TODO:
//  Redo all of this in Django in view/serializer for "Portal tags"
const order = {
    keywords: [
        "Energy",
        "Mood: Dark",
        "Mood: Light",
        "Mood: Emotional",
        "Usage",
        "Genre",
        "World",
        "Era",
        "Type of Production",
        "Tempo",
        "Sounds/Timbre",
    ],
    instruments: [
        "Strings",
        "Keyboards",
        "Guitar/Plectrum: Acoustic",
        "Guitar/Plectrum: Electric",
        "Guitar/Plectrum: Other",
        "Mallets/Tonal Perc",
        "Winds",
        "Brass",
        "Electronic",
        "Hand Drums",
        "Percussion",
        // "Production Elements",  TODO: Portal Django API endpoint
        "World/Ethnic",
        "Vocals",
        "Effects (In-House)",
    ],
};

const renameMapping = {
    World: "Ethnic",
    "Mood: Dark": "Dark",
    "Mood: Light": "Light",
    "Mood: Emotional": "Emotional",
    "Type of Production": "Type of Show",
    "Guitar/Plectrum: Acoustic": "Acoustic",
    "Guitar/Plectrum: Electric": "Electric",
    "Guitar/Plectrum: Other": "Other Plectrum",
};

// Flip the key-value pairs
const flippedRenameMapping = Object.fromEntries(
    Object.entries(renameMapping).map(([key, value]) => [value, key])
);

const useKeywordsInstrumentsTags = (type) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const result = await axios.get(
                    `/api/music/${type}/categories/`
                );
                if (order[type]) {
                    const subcategories = [];
                    result.data.forEach((category) => {
                        category.subcategories.forEach((subcategory) => {
                            const renamedName =
                                flippedRenameMapping[subcategory.name] ||
                                subcategory.name;
                            if (order[type].includes(renamedName)) {
                                subcategories.push({
                                    ...subcategory,
                                    name: renamedName,
                                });
                            }
                        });
                    });

                    const orderedSubcategories = subcategories.sort((a, b) => {
                        const indexA = order[type].indexOf(a.name);
                        const indexB = order[type].indexOf(b.name);
                        return indexA - indexB;
                    });
                    setData(orderedSubcategories);
                } else {
                    setData(result.data);
                }
            } catch (err) {
                console.error("Fetch Error:", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [type]);

    return { data, loading, error };
};

export default useKeywordsInstrumentsTags;
